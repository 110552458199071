import { features } from './features'; 

features.showEstadisticas = false;
features.showAnalisis = true;
features.showVisitas = true;
features.showCargaPAC = true;
features.showTareasFitosanitariasCampaign = true;
features.showTareasFertilizantesCampaign = true;
features.showTareasOtrasCampaign = true;
features.showTareasSiembraCampaign = true;
features.showTractorMarcha = true;
features.showTractorRPM = true;
features.showTractorPresion = true;
features.showTractorBoquillas = true;
features.showVisitasConsultas = true;
features.showDesplazamientoObra = true;
features.showPartida = true;
features.puedeImprimir = true;
features.showAbonado = true;
features.showRegistroTratamiento = false;
features.showPhCaldo = true;
features.showEnviarWhatsapp = true;
features.showEnviarCorreo = true;
features.showEnviarWhatsappVisitas = true;
features.showEnviarCorreoVisitas = true;
features.showTareasBio = true;
features.showOrdenAplicacionProductos = true;
features.showMeteoBtn = true;
features.adminCanSeeAllRecords = true;
features.showVisitasUploadPhotos = true;
features.showAnalisisSectorParcelas = true;
features.showClientesRegepa = true;
features.showCostes = false;
features.showCostesOtros = false;
features.filterAplicadoresByClient = true;
features.showCargaPAC = true;
features.showNPK = false;
features.showParcelasEstacion = true;
features.showTareasFitoMotivoJustificacion = false;
features.showFertilizantesFormShort = true;
features.showTareasFertiGramos = false;
features.showFertilizantesS = true;
features.showParcelasFincasCol = false;
features.showParcelasProvinciaCol = true;
features.showParcelasMunicipioCol = true;
features.userCanSeeClientes = false;
features.showVisitasCultivos = true;
features.showVisitasVariedades = false;
features.userCanSeeCostes = false;
features.showTareasOtros = false;
features.showTareasFitoLote = true;
features.showStock = true;
features.showStockTipoDeDato = true;
features.showStockSemilla = true;
features.showStockProveedor = true;
features.showStockEnvase = true;
features.showFertilizantesAlbaranFactura = true;
features.showAllCultivosPrecedentes = true;
features.showControlSanitario = true;
features.showLocationLatLng = true;
features.showTareasSiembra = true;
features.showTareasSiembraProductos = true;
features.showTareasOtros = true;
features.showLocationLatLng = true;
features.showFitoLocales = true;
features.showFincasGenGml = true;
features.sectorName = 'Paraje';
features.sectoresName = 'Parajes';
features.showGanaderia = true;
features.showWidthFertilizanteNombre = true;
features.showDesplazamientoObra = false;
features.showTareasBioParcelaMultiple = true;
features.showVentasComercioProveedor = true;
features.showStockProveedorClienteDropdown = true;
features.showTareasFertiGramos = true;
features.showBotonPrintParcelas = false;
features.showStockCifProveedor = false;
features.showTareasFertilizantesTipoFertilizacion = true;
features.persisteIdComercio = true;
features.showStockUbicacionDestino = false;
features.showAnalisisNumeroAnalisis = true;
features.showTareasMaquinaria = true;
features.showAgrupacionEntidadEnParcela = true;
features.showParcelasFincasCol = true;
features.showParcelasActivoCol = false;
features.filterAplicadoresByClient = false;
features.showMaquinariaClienteAsignado = true;
features.showMaquinariaAdjuntoPdf = true;
features.showVentas = true;
features.applicationType = 'fitosgest';
features.showUsuariosFirma = true;
export const environment = {
    production: true,
    v: '4.5',
    serverUrl: 'https://pyf.fitosgest.com/',
    logoUrl: 'assets/logo_pyf.png',
    appName: 'pyf',
    features: features
};